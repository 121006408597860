import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as T } from 'react-intl';

import { Layout, SEO } from 'components';

import content from './returns-and-refunds.yml';

const ReturnsRefundsPage = ({ pageContext: { locale }, location }) => (
  <Layout locale={locale}>
    <SEO
      title={content.title}
      description={content.description}
      lang={locale}
      keywords={content.keywords}
      location={location}
      image={content.socialImage}
    />

    <div className='text-wrapper mt-90 mb-90'>
      <h1 className='section-title mb-50'>Returns & Refunds Policy</h1>

      <h2 className='mb-50'>SmileBox&trade; Returns Policy</h2>
      <p>We are happy to offer a refund if you no longer wish to go ahead with your SmileBox&trade; impression kit within 30 days of purchase. Please be aware that we must receive returns within 30 days of delivery – this is irrespective of your chosen payment method.<br/>
      Your SmileBox&trade; impression kit should be returned unopened and unused via recorded mail. You are responsible for any shipping charges when returning this to us.</p>

      <h3 className='mt-40 mb-20'>How to return</h3>
      <p>
      <b>Step 1</b><br/>
      Contact a smile coordinator via your account’s Message tab and confirm the reason for the refund. We will confirm the return address at this stage.
      </p>
      <p>
      <b>Step 2</b><br/>
      Pack up the kit properly to ensure it is protected in transit.
      </p>
      <p>
      <b>Step 3</b><br/>
      Send us the return tracking number so we can keep an eye on the return delivery.
      </p>

      <h3 className='mt-40 mb-20'>Receiving Your Refund</h3>
      <p>
      Once we have received your package, providing the kit is intact and has not been opened or used, we will proceed with the refund within 2 weeks of receiving the parcel.<br/>
      Your refund will be confirmed by email, and funds will be returned to your original payment method. This can take up to 10 days to show on your statement from the time of issue. Please be aware that this varies according to your bank and payment method.<br/>
      If your card has expired since placing the order, please make sure to let us know.<br/><br/>
      The same process is valid for other non custom dental products purchased from Straight Teeth Direct&trade; such as toothpaste, toothbrush and goodies.
      </p>

      <h2 className='mt-50 mb-50'>Aligners Refund Policy</h2>
      <p>Remember, because your aligners are sent to you in stages, it means unlike other companies who send them all in one go, you are eligible for a refund if you stop part way through the process, giving you complete peace of mind.<br/>
      We won’t even ask you to send back the aligners to us!</p>

      <p>Please note that the fitting of your custom aligners will be verified by the clinical team and any issues experienced will be resolved with professional advice and close monitoring. <br/>
      You will alway be given solutions, which involve new impressions and/or production of new aligners, all covered as part of our guarantees, so you can continue with your treatment at no extra cost.</p>

      <h3 className='mt-40 mb-20'>Aligners 1a to 2b:</h3>
      <p>If, for any reason, after receiving the first batch of aligners in your StraightBox&trade; (aligners 1a to 2b), you decide not to continue the process, you will be able to stop there and then, and will only be charged <T id="refunds.production">{txt => txt}</T> for the production cost. <br/>
      We will be able to action a refund / stop further payments if you give us written notice through your account’s Message tab within 30 days of receiving the aligners (as per the tracking number).<br/>
      If you paid in one go, you will be eligible for a refund of <T id="refunds.refund">{txt => txt}</T>.<br/>
      If you chose the 3 monthly instalment plan, you will be eligible for a refund of <T id="refunds.monthly">{txt => txt}</T> and we will cancel future payments.<br/>
      If you chose the monthly plan, we will cancel future payments.</p>

      <h3 className='mt-40 mb-20'>Aligners up to 6b:</h3>
      <p>This is if you decide to stop the process further down the line due to an unexpected financial / medical issue and you have received more aligners, up to 6b.<br/>
      We will be able to action a refund / stop further payments if you give us written notice through your account’s Message tab within 60 days of reception of the new aligners (as per the tracking number).<br/>
      If you paid in one go, you will be eligible for a refund of <T id="refunds.eligible">{txt => txt}</T>.<br/>
      If you chose the 3 monthly instalment plan, we will cancel the last instalment (<T id="refunds.cancel">{txt => txt}</T>).<br/>
      If you chose the monthly plan, we will be able to cancel the last 4 instalments (amounting <T id="refunds.amounting">{txt => txt}</T>).</p>

      <h3 className='mt-40 mb-20'>Receiving Your Refund</h3>
      <p>Once we have received a written request from you within 60 days of receiving up to aligners 6b, we will send you an end of treatment letter for you to sign, confirming your consent to stop all treatment and further aligner deliveries.<br/>
      Once e-signed, we will be able to proceed with the refund, which will be confirmed by email, and funds will be returned to your original payment method. This can take up to 10 days to show on your statement from the time of issue. Please be aware that this varies according to your bank and payment method.<br/>
      If your card has expired since placing the order, please make sure to let us know.</p>

      <p>If you have paid through a finance company, we will be able to give you the money back pro rata as per the terms and you will have to contact the finance company directly should you wish to make changes to your payment plan with them.</p>
    </div>
  </Layout>
);

ReturnsRefundsPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
};

export default ReturnsRefundsPage;
